import React from 'react'
import PropTypes from 'prop-types'

export const PageMainTitle = ({ title }) => {
	return (
		<div className="page-main-title">
			<h2
				className="has-text-weight-bold"
				style={{
					boxShadow: '0.5rem 0 0 #f4f4f4, -0.5rem 0 0 #f4f4f4',
					backgroundColor: '#f4f4f4',
					color: '#001e30',
					padding: '1rem',
				}}
			>
				{title}
			</h2>
		</div>
	)
}

PageMainTitle.propTypes = {
	title: PropTypes.string.isRequired
}

export default PageMainTitle;